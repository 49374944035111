import { get } from 'lodash';
import countryTransform from './country.transform';
import partTransform from './part.transform';
import siteTransform from './site.transform';
import screenCountryTransform from './screen.country.transform';
import screenSiteTransform from './screen.site.transform';

const transformDrillDownData = (data, params) => {
  const { selectedDriverCard } = params;

  if (
    selectedDriverCard === 'screeningFailure' ||
    selectedDriverCard === 'screening'
  ) {
    let partChartData = {};
    const { siteEventsChartData, siteSubjectsChartData } = screenSiteTransform(
      data,
      params,
    );
    const { countryEventsChartData, countrySubjectsChartData } =
      screenCountryTransform(data, params);
    const isTrialComplex = get(data, 'isComplex', false);
    const eventsByCountry = get(data, 'eventsByCountry', []);
    const eventsBySite = get(data, 'eventsBySite', []);
    const subjectsByCountry = get(data, 'subjectsByCountry', []);
    const subjectsBySite = get(data, 'subjectsBySite', []);
    if (isTrialComplex) {
      partChartData = partTransform(data, params);
    }
    const dataByPart = get(data, 'dataByPart', []);

    const { partDataCurrentNumberCurrent, partDataRateCurrent } = partChartData;

    const currentNumberData = {
      ...siteEventsChartData.currentNumber,
      ...countryEventsChartData.currentNumber,
      chartByPartCurrent: partDataCurrentNumberCurrent,
    };

    const rateData = {
      ...siteEventsChartData.rate,
      ...countryEventsChartData.rate,
      chartByPartCurrent: partDataRateCurrent,
    };
    const subjectCurrentNumberData = {
      ...siteSubjectsChartData.currentNumber,
      ...countrySubjectsChartData.currentNumber,
      chartByPartCurrent: partDataCurrentNumberCurrent,
    };

    const subjectRateData = {
      ...siteSubjectsChartData.rate,
      ...countrySubjectsChartData.rate,
      chartByPartCurrent: partDataRateCurrent,
    };

    const transformedData = {
      data: {
        currentNumber: currentNumberData,
        rate: rateData,
        subjectCurrentNumber: subjectCurrentNumberData,
        subjectRate: subjectRateData,
        siteTableData: eventsBySite,
        countryTableData: eventsByCountry,
        subjectSiteTableData: subjectsBySite,
        subjectCountryTableData: subjectsByCountry,
        partTableData: dataByPart,
        isComplex: isTrialComplex,
      },
    };
    return transformedData;
  }
  const countryChartData = countryTransform(data, params);
  const siteChartData = siteTransform(data, params);
  let partChartData = {};

  const isTrialComplex = get(data, 'isComplex', false);

  if (isTrialComplex) {
    partChartData = partTransform(data, params);
  }

  const dataByCountry = get(data, 'dataByCountry', []);
  const dataBySite = get(data, 'dataBySite', []);

  const dataByPart = get(data, 'dataByPart', []);

  const { partDataCurrentNumberCurrent, partDataRateCurrent } = partChartData;

  const currentNumberData = {
    ...siteChartData.currentNumber,
    ...countryChartData.currentNumber,
    chartByPartCurrent: partDataCurrentNumberCurrent,
  };

  const rateData = {
    ...siteChartData.rate,
    ...countryChartData.rate,
    chartByPartCurrent: partDataRateCurrent,
  };

  const transformedData = {
    data: {
      currentNumber: currentNumberData,
      rate: rateData,
      subjectCurrentNumber: null,
      subjectRate: null,
      siteTableData: dataBySite,
      countryTableData: dataByCountry,
      partTableData: dataByPart,
      isComplex: isTrialComplex,
    },
  };
  return transformedData;
};

export default transformDrillDownData;
