import { gray2 } from 'assets/stylesheets/base/_colors';
import DrillDown from '../01_drilldown.abstract';

function generateTooltip(points, selectedCard, rateKey, metric) {
  const pointsLength = points.length;
  const xLabelStyle = `style="font-size:'12px';font-weight:600;line-height:'16px';color:${gray2};font-family:'Open Sans';font-style:'normal';"`;
  const hoveredBarDetailsStyle = `style="color:${gray2};font-family:'Open Sans';font-style:normal;font-size:10px;font-weight:600;line-height:14px;"`;
  let tooltipMarkup = pointsLength
    ? `<div><span ${xLabelStyle}>${points[0].key}</span><br/>`
    : '';
  for (let index = 0; index < pointsLength; index += 1) {
    let labelTitle = '';
    let labelUnit = '';
    if (rateKey === `${selectedCard}Rate`) {
      labelTitle =
        DrillDown.yAxisTitles[
          metric ? `${metric}${selectedCard}` : selectedCard
        ].rate.title;
      labelUnit =
        DrillDown.yAxisTitles[
          metric ? `${metric}${selectedCard}` : selectedCard
        ].rate.unit;
    } else {
      labelTitle =
        DrillDown.yAxisTitles[
          metric ? `${metric}${selectedCard}` : selectedCard
        ].currentNumber.title;
      labelUnit =
        DrillDown.yAxisTitles[
          metric ? `${metric}${selectedCard}` : selectedCard
        ].currentNumber.unit;
    }
    tooltipMarkup += `<span style=${hoveredBarDetailsStyle}>${labelTitle}: ${points[index].y} ${labelUnit}</span>`;
  }
  tooltipMarkup += `</div>`;
  return tooltipMarkup;
}

export default generateTooltip;
