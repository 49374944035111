import { get, groupBy, isNil, orderBy, sortBy } from 'lodash';
import { addFileName } from 'utility';
import { roundOff } from 'utility/math/valueRounder';
import { aqua } from 'assets/stylesheets/base/_colors';
import generateSortComparatorWithNull from 'utility/generateSortComparatorWithNull';
import generateTooltip from './utility/enrollmentDrilldownTooltip';
import DrillDown from './01_drilldown.abstract';
import exportDrilldown from './utility/drilldownExporter';

class CountryDrillDown extends DrillDown {
  _countryData = [];

  _countryMap = {};

  static _xAxis = {
    type: 'category',
    title: {
      text: 'Countries',
    },
    crosshair: true,
  };

  _groupByCountry(dataArray) {
    const countryMap = groupBy(dataArray, 'country.value');
    const countMapKeys = Object.keys(countryMap);
    countMapKeys.forEach((countryKey) => {
      const { country } = countryMap[countryKey][0];
      const value = countryMap[countryKey];
      countryMap[countryKey] = { country, value };
    });
    return countryMap;
  }

  _generateCountrySeries(rateKey) {
    const groupedData = this._groupByCountry(this._countryData);
    const series = Object.values(groupedData).reduce((validData, element) => {
      const barHeight = get(element, `value[0].${rateKey}.value`);
      const magnitude = !isNil(barHeight) ? roundOff(barHeight, 2) : null;
      if (!isNil(magnitude)) {
        const item = {
          country: get(element, 'country'),
          name: get(element, 'country.label'),
          y: magnitude,
          drilldown: get(element, 'country.value'),
        };
        return [...validData, item];
      }
      return validData;
    }, []);
    const isAcsOrderSort = false;
    const sortedSeries = sortBy(
      series,
      generateSortComparatorWithNull('y', isAcsOrderSort),
    );
    return sortedSeries;
  }

  _generateCountrySiteSeries(rateKey) {
    const groupedData = this._groupByCountry(this._siteData);
    const groupedDataCountries = Object.values(groupedData);
    const series = groupedDataCountries.map((element) => {
      const countrySitesData = element.value.reduce(
        (validData, valueElement) => {
          const siteName = get(valueElement, 'siteName.label', null);
          const screenRate = get(valueElement, `${rateKey}.value`, null);
          const screeningRate = !isNil(screenRate)
            ? roundOff(screenRate, 2)
            : null;
          if (!isNil(screenRate)) {
            const screeningData = [siteName, screeningRate];
            return [...validData, screeningData];
          }
          return validData;
        },
        [],
      );
      const isAcsOrderSort = false;
      const sortedCountrySitesData = sortBy(
        countrySitesData,
        generateSortComparatorWithNull('1', isAcsOrderSort),
      );
      const item = {
        name: element.country.label,
        id: element.country.value,
        data: sortedCountrySitesData,
      };
      return item;
    });
    return series;
  }

  _getExport(data, selectedCard, isRateType, metric) {
    return {
      enabled: true,
      allowHTML: true,
      menuItemDefinitions: {
        downloadCSV: {
          onclick: () =>
            exportDrilldown(data, selectedCard, metric, isRateType, 'country'),
          text: 'Download CSV',
        },
      },
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadCSV'],
        },
      },
    };
  }

  _generateYAxis(rateKey, selectedCard) {
    const plotLineValue =
      rateKey === `${selectedCard}Rate`
        ? this._medianRate
        : this._medianCurrentNo;

    const plotLineText = this._generateAverageLineLabel('Country', rateKey);
    const yAxisTitle = { text: this._generateYAxisTitle(rateKey) };
    const yAxisPlotLines = [
      {
        value: plotLineValue,
        width: 1,
        label: {
          text: plotLineText,
          align: 'right',
          verticalAlign: 'top',
          useHTML: true,
          y: -8,
          x: -8,
        },
      },
    ];
    const yAxis = {
      title: yAxisTitle,
      plotLines: yAxisPlotLines,
    };

    return yAxis;
  }

  _generateCountryHighChart(rateKey, selectedCard) {
    const chart = {
      type: 'column',
      scrollablePlotArea: {
        minWidth: 30 * this._countryData.length,
      },
      marginRight: 50,
    };
    const isRateType = rateKey === `${selectedCard}Rate`;
    const nullData = this._countryData.filter((row) =>
      isNil(get(row, `${rateKey}.value`)),
    );
    const notNullData = this._countryData.filter(
      (row) => !isNil(get(row, `${rateKey}.value`)),
    );
    const sortedData = orderBy(notNullData, `${rateKey}.value`, 'desc');

    const yAxis = this._generateYAxis(rateKey, selectedCard);
    const drilldown = {
      breadcrumbs: {
        position: { align: 'right' },
        buttonSpacing: 2,
      },
      series: this._generateCountrySiteSeries(rateKey),
    };
    const series = [
      {
        name: 'Country',
        color: aqua,
        data: this._generateCountrySeries(rateKey),
      },
    ];
    const tooltip = (metric) => ({
      shared: true,
      shape: 'square',
      distance: 20,
      formatter() {
        const { points } = this;
        const countryTooltip = generateTooltip(
          points,
          selectedCard,
          rateKey,
          metric,
        );
        return countryTooltip;
      },
    });

    const chartData = {
      chart,
      accessibility: CountryDrillDown._accessibility,
      title: CountryDrillDown._title,
      xAxis: CountryDrillDown._xAxis,
      yAxis,
      legend: CountryDrillDown._legend,
      plotOptions: this._plotOptions,
      tooltip: tooltip(this._metric),
      series,
      drilldown,
      exporting: this._getExport(
        [...sortedData, nullData],
        selectedCard,
        isRateType,
        this._metric,
      ),
    };
    return chartData;
  }

  generateGraph() {
    const highchartByRate = this._generateCountryHighChart(
      this._rateKey,
      this._selectedCard,
    );
    const highchartByCount = this._generateCountryHighChart(
      this._countKey,
      this._selectedCard,
    );
    const countryChart = 'country';
    const countryRateData = {
      chartByCountry: addFileName(highchartByRate, countryChart),
    };
    const countryNumberData = {
      chartByCountry: addFileName(highchartByCount, countryChart),
    };

    const graphs = {
      rate: countryRateData,
      currentNumber: countryNumberData,
    };

    return graphs;
  }
}

export default CountryDrillDown;
