const isSortable = true;

const SCREENING_COUNTRY_CURRENT_NO_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'screeningCount', label: 'Screened (events)', isSortable },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SUBJECT_SCREENING_COUNTRY_CURRENT_NO_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'screeningCount', label: 'Screened (subjects)', isSortable },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SCREENING_COUNTRY_RATE_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  {
    key: 'screeningRate',
    label: 'Screening event rate (e/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SUBJECT_SCREENING_COUNTRY_RATE_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  {
    key: 'screeningRate',
    label: 'Screening subject rate (s/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SCREENING_SITE_CURRENT_NO_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'screeningCount', label: 'Screened (events)', isSortable },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SUBJECT_SCREENING_SITE_CURRENT_NO_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'screeningCount', label: 'Screened (subjects)', isSortable },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];
const SCREENING_SITE_RATE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  {
    key: 'screeningRate',
    label: 'Screening event rate (e/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SUBJECT_SCREENING_SITE_RATE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  {
    key: 'screeningRate',
    label: 'Screening subject rate (s/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
  { key: 'lastScreened', label: 'Time since last enrolled', isSortable },
];

const SCREEN_FAILURE_COUNTRY_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'screeningCount', label: 'Screened events', isSortable },
  {
    key: 'screeningFailureCount',
    label: 'Screen failure events',
    isSortable,
  },
  {
    key: 'screeningFailureRate',
    label: 'Screen failure event rate %',
    isSortable,
  },
];

const SUBJECT_SCREEN_FAILURE_COUNTRY_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'screeningCount', label: 'Screened subjects', isSortable },
  {
    key: 'screeningFailureCount',
    label: 'Screen failure subjects',
    isSortable,
  },
  {
    key: 'screeningFailureRate',
    label: 'Screen failure subject rate %',
    isSortable,
  },
];

const SCREEN_FAILURE_SITE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'screeningCount', label: 'Screened events', isSortable },
  {
    key: 'screeningFailureCount',
    label: 'Screen failure events',
    isSortable,
  },
  {
    key: 'screeningFailureRate',
    label: 'Screen failure event rate %',
    isSortable,
  },
];

const SUBJECT_SCREEN_FAILURE_SITE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'screeningCount', label: 'Screened subjects', isSortable },
  {
    key: 'screeningFailureCount',
    label: 'Screen failure subjects',
    isSortable,
  },
  {
    key: 'screeningFailureRate',
    label: 'Screen failure subject rate %',
    isSortable,
  },
];

const DROPOUT_COUNTRY_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'enrolledCount', label: 'Enrolled', isSortable },
  { key: 'dropoutCount', label: 'Drop outs', isSortable },
  { key: 'dropoutRate', label: 'Drop out rate %', isSortable },
];

const DROPOUT_SITE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'enrolledCount', label: 'Enrolled', isSortable },
  { key: 'dropoutCount', label: 'Drop outs', isSortable },
  { key: 'dropoutRate', label: 'Drop out rate %', isSortable },
];
const ENROLLMENT_COUNTRY = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
];
const ENROLLMENT_COUNTRY_RATE_TABLE = [
  ...ENROLLMENT_COUNTRY,
  {
    key: 'enrollmentRate',
    label: 'Enrollment rate (s/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
];

const ENROLLMENT_COUNTRY_CURRENT_NO_TABLE = [
  ...ENROLLMENT_COUNTRY,
  { key: 'enrollmentCount', label: 'Enrolled', isSortable },
];

const ENROLLMENT_SITE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteId', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
];

const ENROLLMENT_SITE_CURRENT_NO_TABLE = [
  ...ENROLLMENT_SITE,
  { key: 'enrollmentCount', label: 'Enrolled (subj)', isSortable },
];

const ENROLLMENT_SITE_RATE_TABLE = [
  ...ENROLLMENT_SITE,
  {
    key: 'enrollmentRate',
    label: 'Enrollment rate (s/s/m)',
    isSortable,
    fixedTrailingPoints: 2,
  },
];

const ENROLLMENT_PART = [
  { key: 'partName', label: 'Part', isSortable },
  { key: 'description', label: 'Description' },
];

const enrolledActualLabel = 'Enrolled - actual';
const percentDifferenceLabel = '% difference - actual vs plan';
const enrolledPlanLabel = 'Enrolled - planned EOM';

const ENROLLMENT_PART_CURRENT_NO_CURRENT_PLAN_TABLE = [
  ...ENROLLMENT_PART,
  { key: 'plannedEnrolledCount', label: enrolledPlanLabel },
  { key: 'actualEnrolledCount', label: enrolledActualLabel },
  {
    key: 'actualToCurrentPlanCountPercentageDifference',
    label: percentDifferenceLabel,
  },
];

const ENROLLMENT_PART_RATE_CURRENT_PLAN_TABLE = [
  ...ENROLLMENT_PART,
  {
    key: 'plannedEnrollmentRate',
    label: enrolledPlanLabel,
    fixedTrailingPoints: 2,
  },
  {
    key: 'actualEnrollmentRate',
    label: enrolledActualLabel,
    fixedTrailingPoints: 2,
  },
  {
    key: 'actualToCurrentPlanRatePercentageDifference',
    label: percentDifferenceLabel,
  },
];

const COMPLETION_COUNTRY_TABLE = [
  { key: 'country', label: 'Country', isSortable },
  { key: 'countryId', label: 'Country ID', isSortable },
  { key: 'enrolledCount', label: 'Enrolled', isSortable },
  { key: 'completionCount', label: 'Completed', isSortable },
  { key: 'completionRate', label: 'Completion rate %', isSortable },
];

const COMPLETION_SITE_TABLE = [
  {
    key: 'siteName',
    label: 'Site',
    isSortable,
    className: 'cell-min-w-4 cell-max-w-4 site-name-col',
  },
  { key: 'siteNumber', label: 'Site ID', isSortable },
  { key: 'principalInvestigator', label: 'Principal Investigator', isSortable },
  { key: 'country', label: 'Country', isSortable },
  { key: 'enrolledCount', label: 'Enrolled', isSortable },
  { key: 'completionCount', label: 'Completed', isSortable },
  { key: 'completionRate', label: 'Completion rate %', isSortable },
];

const DRILL_DOWN_TABLE_COLUMN = {
  SCREENING_COUNTRY_CURRENT_NO_TABLE,
  SCREENING_COUNTRY_RATE_TABLE,
  SCREENING_SITE_CURRENT_NO_TABLE,
  SCREENING_SITE_RATE_TABLE,
  SCREEN_FAILURE_COUNTRY_TABLE,
  SCREEN_FAILURE_SITE_TABLE,
  SUBJECT_SCREENING_COUNTRY_CURRENT_NO_TABLE,
  SUBJECT_SCREENING_COUNTRY_RATE_TABLE,
  SUBJECT_SCREENING_SITE_CURRENT_NO_TABLE,
  SUBJECT_SCREENING_SITE_RATE_TABLE,
  SUBJECT_SCREEN_FAILURE_COUNTRY_TABLE,
  SUBJECT_SCREEN_FAILURE_SITE_TABLE,
  DROPOUT_COUNTRY_TABLE,
  DROPOUT_SITE_TABLE,
  ENROLLMENT_COUNTRY_CURRENT_NO_TABLE,
  ENROLLMENT_COUNTRY_RATE_TABLE,
  ENROLLMENT_SITE_CURRENT_NO_TABLE,
  ENROLLMENT_SITE_RATE_TABLE,
  ENROLLMENT_PART_CURRENT_NO_CURRENT_PLAN_TABLE,
  ENROLLMENT_PART_RATE_CURRENT_PLAN_TABLE,
  COMPLETION_COUNTRY_TABLE,
  COMPLETION_SITE_TABLE,
};

export default DRILL_DOWN_TABLE_COLUMN;
