const SITE_NUMBER_COLUMN = {
  label: 'Site ID',
  value: 'siteNumber.label',
  colWidth: { wch: 15 },
};
const SITE_ID_COLUMN = {
  label: 'Site ID',
  value: 'siteId.label',
  colWidth: { wch: 15 },
};

const SITE_DRILLDOWN_COMMON_KEYS = [
  {
    label: 'Site',
    value: 'siteName.label',
    colWidth: { wch: 25 },
  },
  SITE_NUMBER_COLUMN,
  {
    label: 'Principal Investigator',
    value: 'principalInvestigator.label',
    colWidth: { wch: 30 },
  },
  {
    label: 'Country',
    value: 'country.label',
    colWidth: { wch: 10 },
  },
];
const SITE_DRILLDOWN_ENROLLMENT_KEYS = [
  {
    label: 'Site',
    value: 'siteName.label',
    colWidth: { wch: 25 },
  },
  SITE_ID_COLUMN,
  {
    label: 'Principal Investigator',
    value: 'principalInvestigator.label',
    colWidth: { wch: 30 },
  },
  {
    label: 'Country',
    value: 'country.label',
    colWidth: { wch: 10 },
  },
];

const COUNTRY_DRILLDOWN_COMMON_KEYS = [
  {
    label: 'Country',
    value: 'country.label',
    colWidth: { wch: 25 },
  },
  {
    label: 'Country ID',
    value: 'country.value',
    colWidth: { wch: 15 },
  },
];

const ENROLLMENT_RATE_KEYS = [
  {
    label: 'Enrollment rate (s/s/m)',
    value: 'enrollmentRate.label',
    precision: 2,
    fixedTrailingPoints: 2,
    colWidth: { wch: 20 },
  },
];

const SCREENING_RATE_KEYS = [
  {
    label: 'Screening event rate (e/s/m)',
    value: 'screeningRate.label',
    precision: 2,
    fixedTrailingPoints: 2,
    colWidth: { wch: 20 },
  },
];
const SCREENING_COUNT_KEYS = [
  {
    label: 'Screened (events)',
    value: 'screeningCount.label',
    colWidth: { wch: 20 },
  },
];

const SUBJECT_SCREENING_RATE_KEYS = [
  {
    label: 'Screening subject rate (s/s/m)',
    value: 'screeningRate.label',
    precision: 2,
    fixedTrailingPoints: 2,
    colWidth: { wch: 20 },
  },
];
const SUBJECT_SCREENING_COUNT_KEYS = [
  {
    label: 'Screened (subjects)',
    value: 'screeningCount.label',
    colWidth: { wch: 20 },
  },
];

const SUBJECT_SCREEN_FAILURE_KEYS = [
  {
    label: 'Screened (subjects)',
    value: 'screeningCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Screen failure subjects',
    value: 'screeningFailureCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Screen failure subject rate %',
    value: 'screeningFailureRate.label',
    precision: 2,
    colWidth: { wch: 20 },
  },
];

const SCREEN_FAILURE_KEYS = [
  {
    label: 'Screened (events)',
    value: 'screeningCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Screen failure events',
    value: 'screeningFailureCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Screen failure event rate %',
    value: 'screeningFailureRate.label',
    precision: 2,
    colWidth: { wch: 20 },
  },
];
const screenFailureRateAndCountKeys = {
  country: [...COUNTRY_DRILLDOWN_COMMON_KEYS, ...SCREEN_FAILURE_KEYS],
  site: [...SITE_DRILLDOWN_COMMON_KEYS, ...SCREEN_FAILURE_KEYS],
};

const TIME_ENROLLED_COLUMN = {
  label: 'Time since last enrolled',
  value: 'lastScreened.label',
  colWidth: { wch: 10 },
};

const DROPOUT_KEYS = [
  {
    label: 'Enrolled',
    value: 'enrolledCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Drop outs',
    value: 'dropoutCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Drop out rate %',
    value: 'dropoutRate.label',
    precision: 2,
    colWidth: { wch: 20 },
  },
];
const dropoutRateAndCountKeys = {
  country: [...COUNTRY_DRILLDOWN_COMMON_KEYS, ...DROPOUT_KEYS],
  site: [...SITE_DRILLDOWN_COMMON_KEYS, ...DROPOUT_KEYS],
};

const COMPLETION_KEYS = [
  {
    label: 'Enrolled',
    value: 'enrolledCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Completed',
    value: 'completionCount.label',
    colWidth: { wch: 20 },
  },
  {
    label: 'Completion rate %',
    value: 'completionRate.label',
    precision: 2,
    colWidth: { wch: 20 },
  },
];
const completionRateAndCountKeys = {
  country: [...COUNTRY_DRILLDOWN_COMMON_KEYS, ...COMPLETION_KEYS],
  site: [...SITE_DRILLDOWN_COMMON_KEYS, ...COMPLETION_KEYS],
};

const DRILLDOWN_SHEET_KEYS = {
  screening: {
    count: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SCREENING_COUNT_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
      site: [
        ...SITE_DRILLDOWN_COMMON_KEYS,
        ...SCREENING_COUNT_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
    },
    rate: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SCREENING_RATE_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
      site: [
        ...SITE_DRILLDOWN_COMMON_KEYS,
        ...SCREENING_RATE_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
    },
  },
  subjectscreening: {
    count: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREENING_COUNT_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
      site: [
        ...SITE_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREENING_COUNT_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
    },
    rate: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREENING_RATE_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
      site: [
        ...SITE_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREENING_RATE_KEYS,
        TIME_ENROLLED_COLUMN,
      ],
    },
  },
  subjectscreeningFailure: {
    count: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREEN_FAILURE_KEYS,
      ],
      site: [...SITE_DRILLDOWN_COMMON_KEYS, ...SUBJECT_SCREEN_FAILURE_KEYS],
    },
    rate: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        ...SUBJECT_SCREEN_FAILURE_KEYS,
      ],
      site: [...SITE_DRILLDOWN_COMMON_KEYS, ...SUBJECT_SCREEN_FAILURE_KEYS],
    },
  },
  enrollment: {
    count: {
      country: [
        ...COUNTRY_DRILLDOWN_COMMON_KEYS,
        {
          label: 'Enrolled',
          value: 'enrollmentCount.label',
          colWidth: { wch: 20 },
        },
      ],
      site: [
        ...SITE_DRILLDOWN_ENROLLMENT_KEYS,
        {
          label: 'Enrolled (Subj)',
          value: 'enrollmentCount.label',
          colWidth: { wch: 20 },
        },
      ],
    },
    rate: {
      country: [...COUNTRY_DRILLDOWN_COMMON_KEYS, ...ENROLLMENT_RATE_KEYS],
      site: [...SITE_DRILLDOWN_ENROLLMENT_KEYS, ...ENROLLMENT_RATE_KEYS],
    },
  },
  screeningFailure: {
    count: screenFailureRateAndCountKeys,
    rate: screenFailureRateAndCountKeys,
  },
  dropout: {
    count: dropoutRateAndCountKeys,
    rate: dropoutRateAndCountKeys,
  },
  completion: {
    count: completionRateAndCountKeys,
    rate: completionRateAndCountKeys,
  },
};
export default DRILLDOWN_SHEET_KEYS;
