import { get, orderBy } from 'lodash';
import SiteDrillDown from '../drilldown/03_siteDrilldown';

const screenSiteTransform = (data, params) => {
  const { selectedDriverCard } = params;
  const siteEventsDrillDown = new SiteDrillDown(selectedDriverCard);
  const siteSubjectsDrillDown = new SiteDrillDown(selectedDriverCard);

  const eventsBySite = orderBy(
    get(data, 'eventsBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );
  const subjectsBySite = orderBy(
    get(data, 'subjectsBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );
  const medianBySiteCurrentNo = get(data, 'medianBySiteCurrentNo', null);
  const medianBySiteRate = get(data, 'medianBySiteRate', null);

  const medianBySubjectSiteCurrentNo = get(
    data,
    'medianBySubjectSiteCurrentNo',
    null,
  );
  const medianBySubjectSiteRate = get(data, 'medianBySubjectSiteRate', null);

  siteSubjectsDrillDown.siteData = subjectsBySite;
  siteSubjectsDrillDown.medianCurrentNo = medianBySubjectSiteCurrentNo;
  siteSubjectsDrillDown.medianRate = medianBySubjectSiteRate;
  siteSubjectsDrillDown.metric = 'subject';

  siteEventsDrillDown.siteData = eventsBySite;
  siteEventsDrillDown.medianCurrentNo = medianBySiteCurrentNo;
  siteEventsDrillDown.medianRate = medianBySiteRate;

  const siteEventsChartData = siteEventsDrillDown.generateGraph();
  const siteSubjectsChartData = siteSubjectsDrillDown.generateGraph();

  return { siteEventsChartData, siteSubjectsChartData };
};

export default screenSiteTransform;
