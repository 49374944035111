import { get, orderBy } from 'lodash';
import CountryDrillDown from '../drilldown/02_countryDrillDown';

const screenCountryTransform = (data, params) => {
  const { selectedDriverCard } = params;
  const countryEventsDrillDown = new CountryDrillDown(selectedDriverCard);
  const countrySubjectsDrillDown = new CountryDrillDown(selectedDriverCard);

  const eventsByCountry = orderBy(
    get(data, 'eventsByCountry', []),
    (country) => get(country, 'country.label', null),
    'asc',
  );
  const subjectsByCountry = orderBy(
    get(data, 'subjectsByCountry', []),
    (country) => get(country, 'country.label', null),
    'asc',
  );
  const eventsBySite = orderBy(
    get(data, 'eventsBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );
  const subjectsBySite = orderBy(
    get(data, 'subjectsBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );

  const medianBySiteCurrentNo = get(data, 'medianByCountryCurrentNo', null);
  const medianBySiteRate = get(data, 'medianByCountryRate', null);
  const medianBySubjectSiteCurrentNo = get(
    data,
    'medianBySubjectCountryCurrentNo',
    null,
  );
  const medianBySubjectSiteRate = get(data, 'medianBySubjectCountryRate', null);

  countrySubjectsDrillDown.countryData = subjectsByCountry;
  countrySubjectsDrillDown.siteData = subjectsBySite;
  countrySubjectsDrillDown.medianCurrentNo = medianBySubjectSiteCurrentNo;
  countrySubjectsDrillDown.medianRate = medianBySubjectSiteRate;
  countrySubjectsDrillDown.metric = 'subject';

  countryEventsDrillDown.countryData = eventsByCountry;
  countryEventsDrillDown.siteData = eventsBySite;
  countryEventsDrillDown.medianCurrentNo = medianBySiteCurrentNo;
  countryEventsDrillDown.medianRate = medianBySiteRate;

  const countryEventsChartData = countryEventsDrillDown.generateGraph();
  const countrySubjectsChartData = countrySubjectsDrillDown.generateGraph();

  return { countryEventsChartData, countrySubjectsChartData };
};

export default screenCountryTransform;
