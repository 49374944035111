const METRIC_TYPES = {
  CURRENT: 'Current',
  RATE: 'Rate',
};

const TRIAL_MEDIAN_LABEL = 'Trial Median';

function createMetric(metricType, label) {
  const metricTypeLabel = label.toUpperCase();
  return {
    metricType: `${metricType}_${metricTypeLabel}`,
    metricLabel: label,
  };
}

function createMedianMetric(metricType) {
  return {
    metricType: `${metricType}_RATE_MEDIAN`,
    metricLabel: TRIAL_MEDIAN_LABEL,
  };
}

function createCurrentMedianMetric(metricType) {
  return {
    metricType: `${metricType}_CURRENT_MEDIAN`,
    metricLabel: TRIAL_MEDIAN_LABEL,
  };
}

function createMetricObject(metricLabel, metricTypePrefix) {
  const metricType = metricTypePrefix.toUpperCase();
  const subjectMetricType = `SUBJECTS_${metricType}`;
  const eventMetricType = `${metricType}_EVENTS`;
  if (metricType === 'SCREENING' || metricType === 'SCREEN_FAILURE') {
    return {
      metricLabel,
      currentNumber: createMetric(eventMetricType, METRIC_TYPES.CURRENT),
      rate: createMetric(eventMetricType, METRIC_TYPES.RATE),
      currentNumberMedian: createCurrentMedianMetric(eventMetricType),
      rateMedian: createMedianMetric(eventMetricType),
      subjectCurrentNumber: createMetric(
        subjectMetricType,
        METRIC_TYPES.CURRENT,
      ),
      subjectRate: createMetric(subjectMetricType, METRIC_TYPES.RATE),
      subjectRateMedian: createMedianMetric(subjectMetricType),
      subjectCurrentNumberMedian: createCurrentMedianMetric(subjectMetricType),
    };
  }
  return {
    metricLabel,
    currentNumber: createMetric(metricType, METRIC_TYPES.CURRENT),
    rate: createMetric(metricType, METRIC_TYPES.RATE),
    currentNumberMedian: createCurrentMedianMetric(metricType),
    rateMedian: createMedianMetric(metricType),
  };
}

const MEDIAN_CARD_KEY_SOURCE = [
  createMetricObject('Enrollment', 'ENROLLMENT'),
  createMetricObject('Screening', 'SCREENING'),
  createMetricObject('Screen failure', 'SCREEN_FAILURE'),
  createMetricObject('Dropout', 'DROPOUT'),
];

export default MEDIAN_CARD_KEY_SOURCE;
