import { find, get } from 'lodash';
import MEDIAN_CARD_KEY_SOURCE from '../../../../constants/enrollmentMetricsCardKeySource.constant';
import getChartData from './chart';

const commonObj = {
  metricType: '',
  metricValue: null,
  metricUnit: null,
  icon: null,
  metricLabel: '',
};

function generateMedianCardData(data) {
  const cardData = MEDIAN_CARD_KEY_SOURCE.map((metricData) => {
    const metricDataObject = {
      metricLabel: metricData.metricLabel,
      currentNumber: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'currentNumber.metricType', null),
        }),
        metricLabel: get(metricData, 'currentNumber.metricLabel', null),
      },
      rate: {
        ...commonObj,
        ...find(data, { metricType: get(metricData, 'rate.metricType', null) }),
        metricLabel: get(metricData, 'rate.metricLabel', null),
      },
      currentNumberMedian: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'currentNumberMedian.metricType', null),
        }),
        metricLabel: get(metricData, 'currentNumberMedian.metricLabel', null),
      },
      rateMedian: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'rateMedian.metricType', null),
        }),
        metricLabel: get(metricData, 'rateMedian.metricLabel', null),
      },
      subjectCurrentNumber: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'subjectCurrentNumber.metricType', null),
        }),
        metricLabel: get(metricData, 'subjectCurrentNumber.metricLabel', null),
      },
      subjectRate: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'subjectRate.metricType', null),
        }),
        metricLabel: get(metricData, 'subjectRate.metricLabel', null),
      },
      subjectCurrentNumberMedian: {
        ...commonObj,
        ...find(data, {
          metricType: get(
            metricData,
            'subjectCurrentNumberMedian.metricType',
            null,
          ),
        }),
        metricLabel: get(
          metricData,
          'subjectCurrentNumberMedian.metricLabel',
          null,
        ),
      },
      subjectRateMedian: {
        ...commonObj,
        ...find(data, {
          metricType: get(metricData, 'subjectRateMedian.metricType', null),
        }),
        metricLabel: get(metricData, 'subjectRateMedian.metricLabel', null),
      },
    };
    const { metricValue: currentNumber } = metricDataObject.currentNumber;
    const { metricValue: currentNumberMedian } =
      metricDataObject.currentNumberMedian;
    const { metricValue: subjectCurrentNumber } =
      metricDataObject.subjectCurrentNumber;
    const { metricValue: subjectCurrentNumberMedian } =
      metricDataObject.subjectCurrentNumberMedian;

    const { metricValue: rate } = metricDataObject.rate;
    const { metricValue: rateMedian } = metricDataObject.rateMedian;
    const { metricValue: subjectRate } = metricDataObject.subjectRate;
    const { metricValue: subjectRateMedian } =
      metricDataObject.subjectRateMedian;
    const currentChartData = getChartData(
      currentNumber,
      currentNumberMedian,
      2,
    );
    const rateChartData = getChartData(rate, rateMedian, 2);
    const subjectCurrentChartData = getChartData(
      subjectCurrentNumber,
      subjectCurrentNumberMedian,
      2,
    );
    const subjectRateChartData = getChartData(
      subjectRate,
      subjectRateMedian,
      2,
    );
    metricDataObject.currentNumberChart = currentChartData;
    metricDataObject.rateChart = rateChartData;
    metricDataObject.subjectCurrentNumberChart = subjectCurrentChartData;
    metricDataObject.subjectRateChart = subjectRateChartData;

    return metricDataObject;
  });
  return cardData;
}

export default generateMedianCardData;
