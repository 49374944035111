import { NOT_AVAILABLE_PLACEHOLDER } from 'constants';
import { roundOff } from 'utility/math/valueRounder';
import { get, isNil } from 'lodash';

class DrillDown {
  static yAxisTitles = {
    screening: {
      currentNumber: { title: 'Screened', unit: 'events' },
      rate: { title: 'Screening event rate', unit: 'e/s/m' },
    },
    screeningFailure: {
      currentNumber: { title: 'Screen failure', unit: 'events' },
      rate: { title: 'Screen failure event rate', unit: '%' },
    },
    subjectscreening: {
      currentNumber: { title: 'Screened', unit: 'subjects' },
      rate: { title: 'Screening subject rate', unit: 's/s/m' },
    },
    subjectscreeningFailure: {
      currentNumber: { title: 'Screen failure', unit: 'subjects' },
      rate: { title: 'Screen failure subject rate', unit: '%' },
    },
    dropout: {
      currentNumber: { title: 'Dropouts', unit: 'subj' },
      rate: { title: 'Dropout rate', unit: '%' },
    },
    enrollment: {
      currentNumber: { title: 'Enrolled', unit: 'subj' },
      rate: { title: 'Enrollment rate', unit: 's/s/m' },
    },
    completion: {
      currentNumber: { title: 'Completed', unit: 'subj' },
      rate: { title: 'Completion rate', unit: '%' },
    },
  };

  static _title = {
    text: null,
  };

  static _legend = {
    enabled: true,
    align: 'left',
    verticalAlign: 'top',
    margin: 30,
  };

  static _accessibility = {
    announceNewData: { enabled: true },
  };

  _medianRate = null;

  _medianCurrentNo = null;

  _siteData = [];

  _metric = null;

  _plotOptions = {
    series: {
      dataLabels: {
        enabled: false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          enabled: false,
        },
      },
    },
    column: {
      grouping: false,
    },
  };

  constructor(dataKey) {
    this._rateKey = `${dataKey}Rate`;
    this._countKey = `${dataKey}Count`;
    this.chart = { type: 'column' };
    this._selectedCard = dataKey;
  }

  set siteData(siteData) {
    const requiredKeys = [
      'country.label',
      'country.value',
      'siteId.label',
      'siteId.value',
      'siteName.label',
      'siteName.value',
    ];
    this._siteData = this._cleaner(siteData, requiredKeys);
  }

  get siteData() {
    return this._siteData;
  }

  get metric() {
    return this._metric;
  }

  set metric(metric) {
    this._metric = metric;
  }

  set countryData(countryData) {
    if (Array.isArray(countryData)) {
      const requiredKeys = ['country.label', 'country.value'];
      this._countryData = this._cleaner(countryData, requiredKeys);
    }
  }

  get countryData() {
    return this._countryData;
  }

  set rateKey(rateKey) {
    this._rateKey = rateKey;
  }

  get rateKey() {
    return this._rateKey;
  }

  set countKey(countKey) {
    this._countKey = countKey;
  }

  get countKey() {
    return this._countKey;
  }

  set medianRate(medianRate) {
    this._medianRate = roundOff(medianRate, 2);
  }

  get medianRate() {
    return this._medianRate;
  }

  get medianCurrentNo() {
    return this._medianCurrentNo;
  }

  set medianCurrentNo(medianCurrentNo) {
    this._medianCurrentNo = roundOff(medianCurrentNo, 2);
  }

  _cleaner(dataArray, requiredKeys) {
    const filteredData = dataArray.filter((dataItem) => {
      let isAcceptable = true;
      requiredKeys.forEach((requiredKey) => {
        const value = get(dataItem, requiredKey, null);
        isAcceptable = isAcceptable && !isNil(value);
      });
      return isAcceptable;
    });
    return filteredData;
  }

  _generateYAxisTitle = (rateKey) => {
    if (rateKey === `${this._selectedCard}Rate`) {
      const rateTitleDetails = get(
        DrillDown,
        `yAxisTitles.${
          this._metric
            ? `${this._metric}${this._selectedCard}`
            : this._selectedCard
        }.rate`,
        null,
      );
      const rateTitle = get(rateTitleDetails, 'title', null);
      const rateUnit = get(rateTitleDetails, 'unit', null);
      const rateYAxisTitle = `${rateTitle} (${rateUnit})`;
      return rateYAxisTitle;
    }
    const currentNumberTitleDetails = get(
      DrillDown,
      `yAxisTitles.${
        this._metric
          ? `${this._metric}${this._selectedCard}`
          : this._selectedCard
      }.currentNumber`,
      null,
    );
    const currentNumberTitle = get(
      currentNumberTitleDetails,
      'title',
      NOT_AVAILABLE_PLACEHOLDER,
    );
    const currentNumberUnit = get(
      currentNumberTitleDetails,
      'unit',
      NOT_AVAILABLE_PLACEHOLDER,
    );
    const countYAxisTitle = `${currentNumberTitle} (${currentNumberUnit})`;
    return countYAxisTitle;
  };

  _generateAverageLineLabel(selectedToggle, rateKey) {
    if (rateKey === `${this._selectedCard}Rate`) {
      const rateTitleDetails =
        DrillDown.yAxisTitles[
          this._metric
            ? `${this._metric}${this._selectedCard}`
            : this._selectedCard
        ].rate;
      const rateMedian = `Median: ${this._medianRate} (${rateTitleDetails.unit})`;
      return rateMedian;
    }
    const currentNumberTitleDetails =
      DrillDown.yAxisTitles[
        this._metric
          ? `${this._metric}${this._selectedCard}`
          : this._selectedCard
      ].currentNumber;
    const countMedian = `Median: ${this._medianCurrentNo} (${currentNumberTitleDetails.unit})`;
    return countMedian;
  }
}

export default DrillDown;
