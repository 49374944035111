const ENROLLMENT_DRIVER_CONFIGURATION = {
  ENROLMENT_CARD: {
    id: 'Enrollment',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
    secondaryToggleOptions: null,
  },
  SCREENING_CARD: {
    id: 'Screening',
    toggleOptions: [
      {
        label: 'Count',
        key: 'currentNumber',
        secondaryKey: 'subjectCurrentNumber',
      },
      { label: 'Rate', key: 'rate', secondaryKey: 'subjectRate' },
    ],
    secondaryToggleOptions: [
      { label: 'Events', key: 'event' },
      { label: 'Subjects', key: 'subject' },
    ],
  },
  SCREEN_FAILURE_CARD: {
    id: 'Screen failure',
    toggleOptions: [
      {
        label: 'Count',
        key: 'currentNumber',
      },
      { label: 'Rate', key: 'rate' },
    ],
    secondaryToggleOptions: [
      { label: 'Events', key: 'event' },
      { label: 'Subjects', key: 'subject' },
    ],
  },
  DROPOUT_CARD: {
    id: 'Dropout',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
    secondaryToggleOptions: null,
  },
  COMPLETION_CARD: {
    id: 'Completion',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
    secondaryToggleOptions: null,
  },
};

const ENROLLMENT_DRIVER_CONFIGURATION_INITIAL_STATE = {};
Object.values(ENROLLMENT_DRIVER_CONFIGURATION).forEach((configItem) => {
  ENROLLMENT_DRIVER_CONFIGURATION_INITIAL_STATE[configItem.id] = {
    currentToggle: configItem.toggleOptions[0].key,
    secondaryToggle: 'event',
    isActive: false,
  };
});
export { ENROLLMENT_DRIVER_CONFIGURATION_INITIAL_STATE };

export default ENROLLMENT_DRIVER_CONFIGURATION;
