import { get, orderBy } from 'lodash';
import SiteDrillDown from '../drilldown/03_siteDrilldown';

const siteTransform = (data, params) => {
  const { selectedDriverCard } = params;
  const siteDrillDown = new SiteDrillDown(selectedDriverCard);

  const dataBySite = orderBy(
    get(data, 'dataBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );

  const medianBySiteCurrentNo = get(data, 'medianBySiteCurrentNo', null);
  const medianBySiteRate = get(data, 'medianBySiteRate', null);

  siteDrillDown.siteData = dataBySite;
  siteDrillDown.medianCurrentNo = medianBySiteCurrentNo;
  siteDrillDown.medianRate = medianBySiteRate;
  const siteChartData = siteDrillDown.generateGraph();

  return siteChartData;
};

export default siteTransform;
